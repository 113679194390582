import crypto from 'crypto';
import { format } from 'date-fns';
import currency from 'currency.js';
const PhoneNumber = require('awesome-phonenumber');

export const userHasValidPhoneNumber = phone => {
  try {
    if (typeof phone === 'string') {
      const pn = new PhoneNumber(phone, 'US');
      if (pn.isValid()) {
        return { error: null };
      }
    }
    return {
      error: 'Your account needs a valid US phone number to login.',
    };
  } catch (error) {
    console.error(error);
    return {
      error: 'Unable to check your phone number.',
    };
  }
};

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const USD = value =>
  currency(value, {
    separator: ',',
    decimal: '.',
    symbol: '$',
    precision: 2,
    formatWithSymbol: true,
  });

export const handleFaunaResponse = (query, res, sendJSON = false) => {
  try {
    const { error = false, ...data } = query;
    if (error) {
      res.status(error.requestResult.statusCode).end(error.message);
    } else if (data) {
      sendJSON ? res.status(200).json(data) : res.status(200).end();
    } else {
      res.status(500).end('no data received from response');
    }
  } catch {
    res.status(500).end('fatal error');
  }
};
export class FormatDate extends Date {
  addDays = days => {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  addMonths = months => {
    const date = new Date(this.valueOf());
    date.setMonth(date.getDate() + months);
    return date;
  };
  addYears = years => {
    const date = new Date(this.valueOf());
    date.setFullYear(date.getDate() + years);
    return date;
  };
  simplify = () => {
    const date = new Date(this.valueOf());
    return date.toISOString().split('T')[0];
  };
  getWeekends = (date, range) => {
    const weekends = [];
    for (let i = 0; i < range; i++) {
      const day = date.addDays(i);
      if (day.getDay() === 6 || day.getDay() === 0) {
        weekends.push(day.simplify());
      }
    }
    return weekends;
  };
}

export const dateFormatter = date => {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  if (month <= 9) {
    month = '0' + month;
  }
  let day = date.getDate();
  return [year, month, day].join('-');
};

export const createToken = () => {
  return new Promise((resolve, reject) => {
    crypto.randomBytes(20, (err, buffer) => {
      if (err) reject(err);
      const token = buffer.toString('hex');
      resolve(token);
    });
  });
};
/**
 * Check the user scopes for account permissions
 * @param {Array} param0 - the scopes object from fauna
 * @param {String} account - the account id to check
 * @param {String} attribute - the permission level to get 'write_forms', 'read', 'modify_permissions', 'add_users'
 */
export const checkUserPermission = (
  { scopes = [], isAdmin = false },
  account,
  attribute
) => {
  // admins always return true
  if (isAdmin) {
    return isAdmin;
  }
  if (!scopes.length) {
    return false;
  }
  const record = scopes.find(el => el.id == account.id);
  return record ? record[attribute] : false;
};

/**
 * returns id from fauna ref
 * @param {object} faunaRef
 */
export const getIdFromRef = faunaRef => {
  // could be fauna ref or json ref
  try {
    if (faunaRef.ref) {
      return faunaRef.ref.id ? faunaRef.ref.id : faunaRef.ref['@ref'].id;
    } else {
      return faunaRef['@ref'].id;
    }
  } catch (e) {
    return;
  }
};

/**
 * returns id from fauna ref
 * @param {object} faunaRef
 */
export const getTimeFromTimestamp = (
  faunaTime,
  stringFormat = 'yyyy-MM-dd'
) => {
  // could be fauna ref or json ref
  try {
    if (faunaTime['@ts']) {
      return format(new Date(faunaTime['@ts']), stringFormat);
    }
  } catch (e) {
    console.error('Error: Parsing time or invalid format.', e);
    return '';
  }
};

/**
 *
 * @param {str} type email, phone, etc
 * @param {*} str
 */
export const RegexTest = (type, str) => {
  switch (type.toLowerCase()) {
    case 'email':
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(str);
    default:
      return;
  }
};
